<template>
    <div v-if="showBackButton">
        <button @click="goBack"
            class="flex gap-2 items-center text-sm text-gray-500 border border-gray-200 p-2 px-4 rounded-lg transition hover:bg-gray-200 hover:text-gray-800">
            <Icon name="heroicons:arrow-long-left-20-solid" /> Go back
        </button>
    </div>
</template>

<script setup>
const router = useRouter()
const showBackButton = ref(false)

const goBack = () => {
    router.go(-1)
}
onMounted(() => {
    if (window.history.length > 1) {
        if (window.history?.state?.back?.includes('/developers/') || window.history?.state?.back?.includes('/blog/')) {
            showBackButton.value = true
        }
    }
})
</script>
